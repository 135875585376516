.text-bg0 {
  color: var(--gruvbox-bg0);
}

.text-bg0-h {
  color: var(--gruvbox-bg0-h);
}

.text-bg0-s {
  color: var(--gruvbox-bg0-s);
}

.text-bg1 {
  color: var(--gruvbox-bg1);
}

.text-bg2 {
  color: var(--gruvbox-bg2);
}

.text-bg3 {
  color: var(--gruvbox-bg3);
}

.text-bg4 {
  color: var(--gruvbox-bg4);
}

.text-bg {
  color: var(--gruvbox-bg);
}

.text-fg0 {
  color: var(--gruvbox-fg0);
}

.text-fg1 {
  color: var(--gruvbox-fg1);
}

.text-fg2 {
  color: var(--gruvbox-fg2);
}

.text-fg3 {
  color: var(--gruvbox-fg3);
}

.text-fg4 {
  color: var(--gruvbox-fg4);
}

.text-fg {
  color: var(--gruvbox-fg);
}

.text-red {
  color: var(--gruvbox-red);
}

.text-red-muted {
  color: var(--gruvbox-red-muted);
}

.text-green {
  color: var(--gruvbox-green);
}

.text-green-muted {
  color: var(--gruvbox-green-muted);
}

.text-yellow {
  color: var(--gruvbox-yellow);
}

.text-yellow-muted {
  color: var(--gruvbox-yellow-muted);
}

.text-blue {
  color: var(--gruvbox-blue);
}

.text-blue-muted {
  color: var(--gruvbox-blue-muted);
}

.text-purple {
  color: var(--gruvbox-purple);
}

.text-purple-muted {
  color: var(--gruvbox-purple-muted);
}

.text-aqua {
  color: var(--gruvbox-aqua);
}

.text-aqua-muted {
  color: var(--gruvbox-aqua-muted);
}

.text-orange {
  color: var(--gruvbox-orange);
}

.text-orange-muted {
  color: var(--gruvbox-orange-muted);
}

.text-gray {
  color: var(--gruvbox-gray);
}

.float-left {
  float: left;
}

.ml-3 {
  margin-left: 3pt;
}

.p-0 {
  padding: 0;
}

.pr-10 {
  padding-right: 10pt;
}

.m-0 {
  margin: 0;
}

.mt-3 {
  margin: 3pt 0 0;
}

.display-none {
  display: none;
}

:root {
  --gruvbox-bg0: #282828;
  --gruvbox-bg0-h: #1d2021;
  --gruvbox-bg0-s: #32302f;
  --gruvbox-bg1: #3c3836;
  --gruvbox-bg2: #504945;
  --gruvbox-bg3: #665c54;
  --gruvbox-bg4: #7c6f64;
  --gruvbox-bg: var(--gruvbox-bg0);
  --gruvbox-fg0: #fbf1c7;
  --gruvbox-fg1: #ebdbb2;
  --gruvbox-fg2: #d5c4a1;
  --gruvbox-fg3: #bdae93;
  --gruvbox-fg4: #a89984;
  --gruvbox-fg: var(--gruvbox-fg1);
  --gruvbox-red: #fb4934;
  --gruvbox-red-muted: #cc241d;
  --gruvbox-green: #b8bb26;
  --gruvbox-green-muted: #98971a;
  --gruvbox-yellow: #fabd2f;
  --gruvbox-yellow-muted: #d79921;
  --gruvbox-blue: #83a598;
  --gruvbox-blue-muted: #458588;
  --gruvbox-purple: #d3869b;
  --gruvbox-purple-muted: #b16286;
  --gruvbox-aqua: #8ec07c;
  --gruvbox-aqua-muted: #689d6a;
  --gruvbox-orange: #fe8019;
  --gruvbox-orange-muted: #d65d0e;
  --gruvbox-gray: #928374;
  --gruvbox-syntax-keyword: var(--gruvbox-red);
}

@font-face {
  font-family: JetBrains Mono;
  src: url("JetBrainsMono-Regular.74c02fbc.woff2") format("woff2");
}

body {
  background-color: var(--gruvbox-bg);
  color: var(--gruvbox-fg);
  margin: 0;
  padding: 0;
  font-family: JetBrains Mono, monospace;
  font-size: 13pt;
}

#qi-output {
  height: 100%;
  max-height: 100%;
  flex: 1 0 auto;
  margin: 0 0 0 6pt;
  overflow: hidden;
}

#qi-page-container {
  margin: 6pt 0 0;
}

#qi-input-container {
  width: calc(100vw - 12pt);
  margin: 0 0 0 6pt;
  padding: 0;
  display: flex;
  bottom: 0;
  overflow: hidden;
}

#qi-locator {
  margin: 0 1ch 0 0;
  padding: 0;
}

#qi-input, #qi-input:focus {
  background-color: var(--gruvbox-bg0);
  color: var(--gruvbox-fg);
  width: auto;
  max-width: 100%;
  border: none;
  outline: none;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  font-family: JetBrains Mono, monospace;
  font-size: 13pt;
  overflow: hidden;
}

.padded-text, a {
  padding: 0 5pt;
}

a {
  color: var(--gruvbox-aqua);
  text-decoration: none;
}

a:hover {
  background-color: var(--gruvbox-fg);
  color: var(--gruvbox-bg0-h);
}

ul {
  list-style: "- ";
}

hr {
  color: var(--gruvbox-fg);
}

/*# sourceMappingURL=index.547412f3.css.map */
