@import "utilities.scss";

:root {
  /* Background Colors */
  --gruvbox-bg0: #282828;
  --gruvbox-bg0-h: #1d2021;
  --gruvbox-bg0-s: #32302f;
  --gruvbox-bg1: #3c3836;
  --gruvbox-bg2: #504945;
  --gruvbox-bg3: #665c54;
  --gruvbox-bg4: #7c6f64;
  --gruvbox-bg: var(--gruvbox-bg0);

  /* Foreground Colors */
  --gruvbox-fg0: #fbf1c7;
  --gruvbox-fg1: #ebdbb2;
  --gruvbox-fg2: #d5c4a1;
  --gruvbox-fg3: #bdae93;
  --gruvbox-fg4: #a89984;
  --gruvbox-fg: var(--gruvbox-fg1);

  /* Accent Colors */
  --gruvbox-red: #fb4934;
  --gruvbox-red-muted: #cc241d;
  --gruvbox-green: #b8bb26;
  --gruvbox-green-muted: #98971a;
  --gruvbox-yellow: #fabd2f;
  --gruvbox-yellow-muted: #d79921;
  --gruvbox-blue: #83a598;
  --gruvbox-blue-muted: #458588;
  --gruvbox-purple: #d3869b;
  --gruvbox-purple-muted: #b16286;
  --gruvbox-aqua: #8ec07c;
  --gruvbox-aqua-muted: #689d6a;
  --gruvbox-orange: #fe8019;
  --gruvbox-orange-muted: #d65d0e;

  /* Misc Colors */
  --gruvbox-gray: #928374;

  /* Syntax Colors */
  --gruvbox-syntax-keyword: var(--gruvbox-red);
}

@font-face {
  font-family: "JetBrains Mono";
  src: url("../resources/JetBrainsMono-Regular.woff2") format("woff2");
}

body {
  background-color: var(--gruvbox-bg);
  color: var(--gruvbox-fg);

  font-family: "JetBrains Mono", monospace;
  font-size: 13pt;

  margin: 0;
  padding: 0;
}

#qi-output {
  flex: 0 0 auto;
  flex-grow: 1;

  height: 100%;
  max-height: 100%;

  overflow: hidden;
  margin: 0 0 0 6pt;
}

#qi-page-container {
  margin: 6pt 0 0 0;
}

#qi-input-container {
  display: flex;
  margin: 0 0 0 6pt;
  padding: 0;
  bottom: 0;
  overflow: hidden;
  width: calc(100vw - 12pt);
}

#qi-locator {
  margin: 0 1ch 0 0;
  padding: 0;
}

#qi-input,
#qi-input:focus {
  flex: 0 0 auto;
  flex-grow: 1;

  background-color: var(--gruvbox-bg0);
  color: var(--gruvbox-fg);

  font-family: "JetBrains Mono", monospace;
  font-size: 13pt;

  width: auto;
  max-width: 100%;
  overflow: hidden;

  padding: 0;
  margin: 0;

  border: none;
  outline: none;
}

.padded-text {
  padding: 0 5pt 0 5pt;
}

a {
  @extend .padded-text;
  text-decoration: none;
  color: var(--gruvbox-aqua);
}

a:hover {
  background-color: var(--gruvbox-fg);
  color: var(--gruvbox-bg0-h);
}

ul {
  list-style: "- " outside;
}

hr {
  color: var(--gruvbox-fg);
}
