.text-bg0 {
  color: var(--gruvbox-bg0);
}

.text-bg0-h {
  color: var(--gruvbox-bg0-h);
}

.text-bg0-s {
  color: var(--gruvbox-bg0-s);
}

.text-bg1 {
  color: var(--gruvbox-bg1);
}

.text-bg2 {
  color: var(--gruvbox-bg2);
}

.text-bg3 {
  color: var(--gruvbox-bg3);
}

.text-bg4 {
  color: var(--gruvbox-bg4);
}

.text-bg {
  color: var(--gruvbox-bg);
}

.text-fg0 {
  color: var(--gruvbox-fg0);
}

.text-fg1 {
  color: var(--gruvbox-fg1);
}

.text-fg2 {
  color: var(--gruvbox-fg2);
}

.text-fg3 {
  color: var(--gruvbox-fg3);
}

.text-fg4 {
  color: var(--gruvbox-fg4);
}

.text-fg {
  color: var(--gruvbox-fg);
}

.text-red {
  color: var(--gruvbox-red);
}

.text-red-muted {
  color: var(--gruvbox-red-muted);
}

.text-green {
  color: var(--gruvbox-green);
}

.text-green-muted {
  color: var(--gruvbox-green-muted);
}

.text-yellow {
  color: var(--gruvbox-yellow);
}

.text-yellow-muted {
  color: var(--gruvbox-yellow-muted);
}

.text-blue {
  color: var(--gruvbox-blue);
}

.text-blue-muted {
  color: var(--gruvbox-blue-muted);
}

.text-purple {
  color: var(--gruvbox-purple);
}

.text-purple-muted {
  color: var(--gruvbox-purple-muted);
}

.text-aqua {
  color: var(--gruvbox-aqua);
}

.text-aqua-muted {
  color: var(--gruvbox-aqua-muted);
}

.text-orange {
  color: var(--gruvbox-orange);
}

.text-orange-muted {
  color: var(--gruvbox-orange-muted);
}

.text-gray {
  color: var(--gruvbox-gray);
}

.float-left {
  float: left;
}

.ml-3 {
  margin-left: 3pt;
}

.p-0 {
  padding: 0;
}

.pr-10 {
  padding-right: 10pt;
}

.m-0 {
  margin: 0;
}

.mt-3 {
  margin: 3pt 0 0 0;
}

.display-none {
  display: none;
}